@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Regular.otf') format('truetype');
}

/*Thin*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Thin.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Thin_Italic.otf') format('truetype');
}

/*ExtraLight*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Xlight.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Xlight_Italic.otf') format('truetype');
}

/*Light*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Light.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Light_Italic.otf') format('truetype');
}

/*Normal(Regular)*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Regular.otf') format('truetype');
}

/*Medium*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Medium.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Medium_Italic.otf') format('truetype');
}

/*Semi Bold*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_SemiBold.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_SemiBold_Italic.otf') format('truetype');
}

/*Bold*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Bold.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Bold_Italic.otf') format('truetype');
}

/*Heavy*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Heavy.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Heavy_Italic.otf') format('truetype');
}

/*Black*/
@font-face {
    font-family: SVN Gilroy;
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Black.otf') format('truetype');
}

@font-face {
    font-family: SVN Gilroy;
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/SVN-Gilroy/SVN-Gilroy_Black_Italic.otf') format('truetype');
}