@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/fonts/font_svn_gilroy.css");
/* @import url("./assets/fonts/font_lexend.css"); */

@layer base {
  button {
    @apply border border-black rounded-lg px-4 py-2 text-sm md:text-base text-gray-900 cursor-pointer;
  }

  .button-style1 {
    @apply text-white border-white relative px-8 py-2 rounded-md bg-transparent isolation-auto z-10 border-2 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-gray-300 before:-z-10 before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700;
  }
  
  .wrapper {
    @apply bg-custom-light p-custom-wrapper-mobile md:p-custom-wrapper h-auto w-full;
  }

  .h1-title {
    @apply text-center py-10 text-2xl md:text-4xl font-bold uppercase text-black;
  }

  .h2-title {
    @apply pt-4 text-xl font-bold uppercase text-black;
  }

  .h4-title {
    @apply pt-2 font-medium text-sm md:text-xl text-black;
  }

  .contact-input {
    @apply bg-white text-black my-3 w-full;
  }

  p {
    @apply text-xs md:text-base tracking-wide
  }
}

/* @font-face {
  font-family: 'SVN Gilroy';
  src: local('SVN-Gilroy_Regular'), url('./assets/fonts/SVN-Gilroy/SVN-Gilroy_Regular.otf') format('truetype');
  font-style: normal;
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* user-select: none; */
  font-family: "SVN Gilroy";
    /* font-family: "lexend"; */
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "SVN Gilroy";
    /* font-family: "lexend"; */
}
