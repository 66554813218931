.App {
  width: 100%;
  height: 100%;
}

@keyframes ring-ring {
  0% {
    transform: rotate(0);
  }
  1.5% {
    transform: rotate(40deg);
  }
  3% {
    transform: rotate(-40deg);
  }
  4.5% {
    transform: rotate(30deg);
  }
  6% {
    transform: rotate(-30deg);
  }
  7.5% {
    transform: rotate(20deg);
  }
  9% {
    transform: rotate(-20deg);
  }
  10.5% {
    transform: rotate(10deg);
  }
  12% {
    transform: rotate(-10deg);
  }
  13.5% {
    transform: rotate(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(210,182,138, 0.5);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.ringing-phone {
  animation: ring-ring 1.5s linear infinite;
}

.pulse-circle {
  position: relative;
}

.pulse-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: transparent;
  animation: pulse 1.8s infinite;
}

/* Button */
.button-style_prev {
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  overflow: hidden;
}

.button-style_prev span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button-style_prev span:after {
  content: "\00ab";
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transition: 0.5s;
}

.button-style_prev:hover span {
  padding-left: 25px;
  color: black
}

.button-style_prev:hover span:after {
  opacity: 1;
  left: 0;
}


.button-style_next {
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  overflow: hidden;
}

.button-style_next span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button-style_next span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button-style_next:hover span {
  padding-right: 25px;
}

.button-style_next:hover span:after {
  opacity: 1;
  right: 0;
}

/* Modal Button */

.popconfirm-ok-button {
  background-color: transparent;
  color: #D2B68A;
  border:1px solid #D2B68A;
  box-shadow: none;
}

.popconfirm-ok-button:hover {
  background-color: #D2B68A !important; /* Tạo hiệu ứng hover nhẹ */
  color: white;
}

.popconfirm-cancel-button {
}

.popconfirm-cancel-button:hover {
  color: #D2B68A !important;
  border: 1px solid #D2B68A !important;
}

/* Submit */

.button-style_sumbit {
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  padding: 10px 40px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-top: 0 !important;
  overflow: hidden;
  color: white;
  background: #D2B68A;
  border: 1px solid white;
}

.button-style_sumbit span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button-style_sumbit span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button-style_sumbit:hover span {
  padding-right: 25px;
}

.button-style_sumbit:hover span:after {
  opacity: 1;
  right: 0;
}

/* Input Radio */
/* Ẩn input radio mặc định */
input[type="radio"] {
  appearance: none; /* Xóa style mặc định */
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none; /* Xóa outline khi focus */
  margin: 0; /* Xóa margin mặc định */
  width: 16px; /* Đặt kích thước của radio */
  height: 16px;
  border-radius: 50%; /* Tạo hình tròn */
  border: 1px solid #D2B68A; /* Viền màu */
  cursor: pointer; /* Thay đổi con trỏ khi hover */
  position: relative;
  margin-right: 4px;
}

/* Thay đổi màu nền khi radio được chọn */
input[type="radio"]:checked {
  background-color: #D2B68A; /* Màu nền khi được chọn */
}

/* Thêm hiệu ứng dot khi radio được chọn */
input[type="radio"]:checked::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* Input Antd */


/* .ant-input-outlined:focus {
  border-width: 0px;
  box-shadow: none;
} */

.ant-input-outlined:focus,
.ant-input-outlined:hover {
  border-color: #D2B68A;
  border-width: 1px;
  box-shadow: none;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 4px; /* Độ rộng của thanh cuộn */
  height: 4px; /* Độ rộng của thanh cuộn */
  background-color: #ffffff; /* Màu nền của thanh cuộn */
}

/* Tùy chỉnh slider của thanh cuộn */
::-webkit-scrollbar-thumb {
  background-color: #D2B68A; /* Màu của slider */
  border-radius: 4px; /* Bo góc cho slider */
}


/* Pagination */
.ant-pagination-item-active {
  border-color: #D2B68A !important;
}
.ant-pagination-item-active a {
  color: #D2B68A !important;
  border-color: #D2B68A !important;
}

.ant-pagination-item:hover a {
  color: #D2B68A !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  color: #D2B68A !important;
}

.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  border-color: #D2B68A !important;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: #D2B68A !important;
}

.ant-pagination-options .ant-select-selector {
  border-color: #D2B68A !important;
}